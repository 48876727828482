import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'

const Container = styled.div`
  background: ${({ theme }) => theme.gradient};
  width: 100vw;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Logo = styled.img`
  width: 200px;
`

const Form = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  label {
    font-size: 20px;
    color: white;
  }
  input {
    margin-top: 6px;
    min-width: 18em;
    height: 40px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: 'Montserrat';
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 31px;
    transition: all 250ms ease-in-out;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 12px 0.8px ${({ theme }) => theme.colors.secondary};
    }
  }
`
const LoginButton = styled.button`
  cursor: pointer;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: white;
  border-radius: 4px;
  height: 40px;
  min-width: 18em;
  width: 100%;
  padding: 0px 10px;
  font-size: 16px;
  font-family: 'Montserrat';
  border: 0;
`

const Login = () => {
  const { enqueueSnackbar } = useSnackbar()
  const firebase = useFirebase()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const login = async () => {
    try {
      await firebase.login({ email: username, password })
    } catch (e) {
      if (e.message) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        })
      }
    }
  }
  return (
    <Container>
      <Content>
        <Logo src={'/logo.png'} />
        <Form>
          <FormGroup>
            <label htmlFor="E-Mail">E-Mail</label>
            <input
              type="text"
              name="E-Mail"
              placeholder="E-Mail"
              value={username}
              onChange={(c) => setUsername(c.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="Passwort">Passwort</label>
            <input
              type="password"
              name="Passwort"
              placeholder="Passwort"
              value={password}
              onChange={(c) => setPassword(c.target.value)}
            />
          </FormGroup>
        </Form>
        <LoginButton type="button" onClick={login}>
          Login
        </LoginButton>
      </Content>
    </Container>
  )
}
export default Login
