import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import clsx from 'clsx'
import React from 'react'
import {
  IoHomeOutline,
  IoListOutline,
  IoMenuOutline,
  IoSettingsOutline,
  IoTvOutline,
  IoLogOutOutline,
  IoDocumentTextOutline
} from 'react-icons/io5'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  theme as customTheme,
  breakpoints
} from '@peakconcepts/leeeds-customer-portal-shared'
import { Helmet } from 'react-helmet'
import { selectIsAdmin } from '../selectors/user'
import { useSelector } from 'react-redux'

const drawerWidth = 240
export const appBarHeight = 65
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      height: appBarHeight,
      zIndex: theme.zIndex.drawer + 1,
      background: customTheme.gradient,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      height: appBarHeight,
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: 60,
      [breakpoints.up('sm')]: {
        width: 70
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 10px',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      width: '100%',
      marginTop: appBarHeight,
      height: `calc(100vh - ${appBarHeight}px)`,
      maxHeight: `calc(100vh - ${appBarHeight}px)`,
      overflow: 'hidden'
    },
    list: {
      paddingLeft: '7px'
    }
  })
)

const IconList = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  overflow: hidden;
  div:not(:last-of-type) {
    border-bottom: ${({ theme }) => theme.border};
  }
`

const IconListItem = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  svg {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin: 0 23px;
  }
`
const IconListItemText = styled.span`
  font-weight: 500;
  display: flex;
  flex: 1 1 100%;
`
const Logo = styled.img`
  height: 30px;
`
const CustomDrawer = ({ children }: any) => {
  const firebase = useFirebase()
  const classes = useStyles()
  const history = useHistory()
  const isAdmin = useSelector(selectIsAdmin)
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const screens = [
    {
      index: 1,
      label: 'Datenpflege',
      link: '/onboarding',
      Icon: IoHomeOutline,
      hidden: !isAdmin
    },
    {
      index: 2,
      label: 'Dashboard',
      link: '/dashboard',
      Icon: IoTvOutline,
      hidden: false
    },
    {
      index: 3,
      label: 'Anfragen',
      link: '/requests',
      Icon: IoListOutline,
      hidden: false
    },
    {
      index: 4,
      label: 'Dokumente',
      link: '/documents',
      Icon: IoDocumentTextOutline,
      hidden: false
    },
    {
      index: 5,
      label: 'Einstellungen',
      link: '/settings',
      Icon: IoSettingsOutline,
      hidden: !isAdmin
    }
  ]
    .filter((s) => !s.hidden)
    .sort((a, b) => a.index - b.index)

  return (
    <div className={classes.root}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/8134226.js"></script>
      </Helmet>

      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}>
            <IoMenuOutline />
          </IconButton>
          <Logo src="./logo.png" />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <IconList>
          {screens.map(({ label, link, Icon }, index) => (
            <IconListItem key={link} onClick={() => history.push(link)}>
              <Icon />
              <IconListItemText>{label}</IconListItemText>
            </IconListItem>
          ))}
        </IconList>
        <IconListItem onClick={() => firebase.logout()}>
          <IoLogOutOutline />
          <IconListItemText>Logout</IconListItemText>
        </IconListItem>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  )
}
export default CustomDrawer
