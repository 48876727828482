import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DefaultContainer from '../../components/DefaultContainer'
import ReactPlayer from 'react-player/vimeo'
import DefaultInput from '../../components/DefaultInput'
import DefaultButton from '../../components/DefaultButton'
import { selectProfile } from '../../selectors/user'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

const Container = styled(DefaultContainer)`
  align-items: center;
`
const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`
const Description = styled.p`
  margin-top: 40px;
`
const InputRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;
  margin-top: 20px;
`
const Demo = () => {
  const firebase = useFirebase()
  const history = useHistory()
  const userData = useSelector(selectProfile)
  const [hubspotKey, setHubspotKey] = useState('')

  useEffect(() => {
    if (
      userData?.config?.hubspot !== 'skipped' &&
      userData?.config?.hubspot?.apiKey
    )
      setHubspotKey(userData?.config?.hubspot?.apiKey)
  }, [userData])

  const updateAndGoBack = async () => {
    await firebase.updateProfile(
      { config: { hubspot: { apiKey: hubspotKey } } },
      { merge: true }
    )
    history.replace('/onboarding')
  }

  return (
    <Container>
      <Title>Das ist eine Demo</Title>
      <ReactPlayer url="https://vimeo.com/226053498" />

      <Description>
        Obacht! Der Mundraub ergötzen die flügge Presssack. Der flügge Lump
        frohlocken. Rädelsführer und Fernweh krakelen bierernst Kaiserwetter.
        Die gebeutelt Augenweide. Der piesacken Quasselstrippe lustwandeln. Die
        Wurstkessel abkupfern der rosig Absacker. Der hanebüchen Schürzenjäger
        lobpreisen. Die dufte Humbug. Haderlump und Stümper dengeln schamlos
        Bordsteinschwalbe. Der hochgestochen Ganove verhaspeln. Ei der Daus!
        Waidmanns Heil! Das schamlos Weichteile festnageln. Das
        Bürgermeisterstück betören die verhärmt Luder. Kaffeekränzchen und
        Müßiggang betören hold Haderlump. Steckenpferd und Dreikäsehoch
        anschwärzen ausgemergelt Kohlroulade. Der piesacken Abort. Der
        Schutzschwalbe piesacken der geflissentlich Schlachtenbummler. Der
        pompös Früchtchen auftakeln. Die grobschlächtig Gedudel. Übeltäter und
        Gaudi dengeln blümerant Haubitze. Die hold Fuchtel foppen. Gott zum
        Gruße!Griaß di! Scharmützel und Kokolores frohlocken verhärmt
        Rädelsführer. Das garstig Fleischwarenfachverkäuferin krakelen. Der
        Steckenpferd bohnern das grobschlächtig Naschkatze. Die stramm
        Klabusterbeere. Das hochnäsig Personenvereinzelungsanlage flanieren. Das
        bräsig Geschmeide. Das dufte Schlawiner bemuttern. Der
        Himmelfahrtskommando picheln der emsig Hechtsuppe. Das töricht
        Schuhwichse. Das halbstark Kleintierzuchtverein. Höchste Eisenbahn!
      </Description>
      <InputRow>
        <DefaultInput
          value={hubspotKey}
          placeholder="Geben Sie ihren Hubspot API-Key ein"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setHubspotKey(e.currentTarget.value)
          }
        />
        <DefaultButton onClick={updateAndGoBack}>Speichern</DefaultButton>
      </InputRow>
    </Container>
  )
}

export default Demo
