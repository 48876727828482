import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import './css/reset.css'
import './css/default.css'

import Drawer from './components/Drawer'
// Pages
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Onboarding from './pages/Onboarding'
import Demo from './pages/onboarding/Demo'

import { useSnackbar } from 'notistack'
import { selectAuth, selectProfile } from './selectors/user'
import Requests from './pages/Requests'
import Documents from './pages/Documents'

function App() {
  const firebase = useFirebase()

  const { enqueueSnackbar } = useSnackbar()

  const auth = useSelector(selectAuth)
  const profile = useSelector(selectProfile)
  useEffect(() => {
    if (
      isLoaded(profile) &&
      !isEmpty(profile) &&
      profile?.properties?.noAccess
    ) {
      // has no access
      enqueueSnackbar(
        'Ihr Account ist nicht aktiviert. Bitte kontaktieren Sie den Support',
        {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        }
      )
      firebase.logout()
    }
  }, [enqueueSnackbar, firebase, profile])
  return (
    <div className="App">
      <Router>
        {isLoaded(auth) && isEmpty(auth) ? (
          <Login />
        ) : (
          <Drawer>
            <Switch>
              <Route exact path="/onboarding">
                <Onboarding />
                </Route>
                <Route exact path="/onboarding/demo">
                <Demo />
              </Route>
              <Route exact path="/onboarding/clockify">
                <div>clockify</div>
              </Route>
              <Route exact path="/requests">
                <Requests />
              </Route>
              <Route exact path="/documents">
                <Documents />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="*" exact>
                <Redirect from="*" to="/" />
              </Route>
            </Switch>
          </Drawer>
        )}
      </Router>
    </div>
  )
}

export default App
