import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
const Loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  )
}

export default Loading
