import React from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectUID } from '../selectors/user'
import { useFirestoreConnect } from 'react-redux-firebase'
import {
  selectUserMetadataFullList,
  selectUserMetadataLast30Days,
  selectUserMetadataLast7Days,
  selectUserMetadataToday,
  selectUserMetadataTotal
} from '../selectors/metadata'
import DefaultContainer from '../components/DefaultContainer'
import {
  Chart,
  breakpoints,
  DashboardCard
} from '@peakconcepts/leeeds-customer-portal-shared'
import { useHistory } from 'react-router'

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  ${breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
  ${breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0px;
  }
`
const Dashboard = () => {
  const history = useHistory()
  const uid = useSelector(selectUID)
  useFirestoreConnect(`users_meta/${uid}`)
  const total = useSelector(selectUserMetadataTotal)
  const last7days = useSelector(selectUserMetadataLast7Days)
  const last30days = useSelector(selectUserMetadataLast30Days)
  const today = useSelector(selectUserMetadataToday)
  const fullRequestList = useSelector(selectUserMetadataFullList)
  return (
    <DefaultContainer>
      <CardsContainer>
        <DashboardCard
          title={today}
          description="Anfragen heute"
          type="today"
          onClick={() => history.push('/requests')}
        />
        <DashboardCard
          title={last7days}
          description="Anfragen letzte 7 Tage"
          type="last7days"
          onClick={() => history.push('/requests')}
        />

        <DashboardCard
          title={last30days}
          description="Anfragen letze 30 Tage"
          type="last30days"
          onClick={() => history.push('/requests')}
        />

        <DashboardCard
          title={total}
          description="Anfragen insgesamt"
          type="alltime"
          onClick={() => history.push('/requests')}
        />
      </CardsContainer>
      <Chart timestampList={fullRequestList} />
    </DefaultContainer>
  )
}

export default Dashboard
