import { FirestoreReduxReducer } from '../interfaces/redux'
import { createSelector } from 'reselect'
import { selectUID } from './user'
import { MetaData } from '@peakconcepts/leeeds-customer-portal-interfaces'
import { DateTime } from 'luxon'

const selectMetaDataTable = ({
  firestore
}: {
  firestore: FirestoreReduxReducer
}) => firestore?.data?.users_meta

export const selectUserMetadata = createSelector(
  selectMetaDataTable,
  selectUID,
  (metaData, uid) => metaData?.[uid] as MetaData | undefined
)
export const selectUserMetadataTotal = createSelector(
  selectUserMetadata,
  (metaData) => metaData?.totalRequests || 0
)

export const selectUserMetadataFullList = createSelector(
  selectUserMetadata,
  (metaData) => metaData?.fullRequestList || []
)
export const selectUserMetadataToday = createSelector(
  selectUserMetadata,
  (metaData) => {
    const startOfDay = DateTime.now().startOf('day').toMillis()

    return metaData?.requestList.filter((r) => r > startOfDay).length || 0
  }
)
export const selectUserMetadataLast7Days = createSelector(
  selectUserMetadata,
  (metaData) => {
    const startOfDay7DaysAgo = DateTime.now()
      .startOf('day')
      .minus({ days: 7 })
      .toMillis()
    return (
      metaData?.requestList.filter((r) => r > startOfDay7DaysAgo).length || 0
    )
  }
)

export const selectUserMetadataLast30Days = createSelector(
  selectUserMetadata,
  (metaData) => {
    const startOfDay30DaysAgo = DateTime.now()
      .startOf('day')
      .minus({ days: 30 })
      .toMillis()
    return (
      metaData?.requestList.filter((r) => r > startOfDay30DaysAgo).length || 0
    )
  }
)
