import styled from 'styled-components'
const DefaultButton = styled.button`
  padding: 10px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.primary};
  min-width: 100px;
  color: white;
  font-weight: 600;
  cursor: pointer;
`

export default DefaultButton
