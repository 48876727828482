import { createSelector } from 'reselect'
import { selectProfile } from './user'

export const selectHubspotProgress = createSelector(
  selectProfile,
  (profile) => {
    if (
      profile?.config?.hubspot === 'skipped' ||
      (profile?.config?.hubspot?.apiKey &&
        profile?.config?.hubspot?.apiKey?.length > 0)
    )
      return 1
    return 0
  }
)
