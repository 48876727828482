/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { IoDocumentAttachOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import DefaultContainer from '../components/DefaultContainer'
import { selectUID } from '../selectors/user'
import styled from 'styled-components'
import { Reference, FullMetadata } from '@firebase/storage-types'
import { DateTime } from 'luxon'
import Loading from '../components/Loading'
import { downloadFirebaseURL } from '@peakconcepts/leeeds-customer-portal-shared'

const Title = styled.h3`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
`

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  div {
    border-top: ${({ theme }) => theme.border};
  }
  div:last-of-type {
    border-bottom: ${({ theme }) => theme.border};
  }
`
const DocumentItem = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: center;
  cursor: pointer;
  svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
`
const DocumentName = styled.span`
  font-weight: 500;
  font-size: 18px;
`
const TimeStamp = styled.span`
  margin-left: auto;
  font-weight: 400;
  font-size: 14px;
`
const NoDocuments = styled.p`
  font-size: 20px;
  font-weight: 500;
`

interface Document {
  name: string
  ref: Reference
  metadata: FullMetadata
}

const Documents = () => {
  const uid = useSelector(selectUID)
  const firebase = useFirebase()
  const [documents, setDocuments] = useState<Document[]>([])
  const [loading, setLoading] = useState(true)

  const getAllItems = async () => {
    try {
      const allItems = await (
        await firebase.storage().ref(`documents/${uid}`).listAll()
      ).items
      const mapped: Document[] = []
      for (const i of allItems) {
        const metadata = await i.getMetadata()
        mapped.push({ name: i.name, ref: i, metadata })
      }
      console.log(`mapped`, mapped)
      setDocuments(mapped)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const openItem = async (ref: Reference) => {
    const url = await ref.getDownloadURL()
    downloadFirebaseURL(url, ref.name)
  }

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    if (uid) getAllItems()
  }, [uid])

  return (
    <DefaultContainer>
      <Title>Ihre Dokumente</Title>
      {loading ? (
        <Loading />
      ) : documents.length === 0 ? (
        <NoDocuments>Sie haben noch keine hinterlegten Dokumente</NoDocuments>
      ) : (
        <DocumentList>
          {documents.map((d) => (
            <DocumentItem onClick={() => openItem(d.ref)}>
              <IoDocumentAttachOutline />
              <DocumentName>{d.name}</DocumentName>
              <TimeStamp>
                {DateTime.fromISO(d.metadata.updated)
                  .setLocale('de')
                  .toFormat('dd. LLL yyyy')}
              </TimeStamp>
            </DocumentItem>
          ))}
        </DocumentList>
      )}
    </DefaultContainer>
  )
}

export default Documents
