import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 10px;

  progress[value] {
    width: 100%;
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 10px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.lightgray};
  }

  progress[value]::-webkit-progress-value {
    height: 10px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.secondary};
    transition: width 1s;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const ProgressBar = ({ value }: { value: number }) => {
  return (
    <Container>
      <progress value={Math.min(Math.max(value, 1), 100)} max={100} />
    </Container>
  )
}

let interval: NodeJS.Timeout | null = null
const Progress = () => {
  const [value, setValue] = useState(0)
  const startProgress = () => {
    interval = setInterval(() => {
      setValue((v) => v + 1)
    }, 100)
  }
  useEffect(() => {
    startProgress()
  }, [])
  useEffect(() => {
    if (value >= 50) {
      clearInterval(interval as NodeJS.Timeout)
    }
  }, [value])
  return <ProgressBar value={value} />
}

export default Progress
