import React from 'react'
import styled from 'styled-components'
import DefaultContainer from '../components/DefaultContainer'
import OnboardingCard from '../components/OnboardingCard'
import Progress from '../components/Progress'
import { breakpoints } from '@peakconcepts/leeeds-customer-portal-shared'
import { selectHubspotProgress } from '../selectors/onboarding'
import { useSelector } from 'react-redux'
const Title = styled.h1`
  font-size: 35px;
  font-weight: 700;
  margin: 50px 0 0;
`
const Subtitle = styled.h3`
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 50px;
`
const Description = styled.p`
  font-size: 18px;
  margin: 0 0 20px;
`
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  ${breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
  ${breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0px;
  }
`
const Onboarding = () => {
  const hubspotProgress = useSelector(selectHubspotProgress)
  return (
    <DefaultContainer>
      <Progress />
      <Title>Herzlich Willkommen</Title>
      <Subtitle>in Ihrem Leeeds Dashboard</Subtitle>

      <Description>Bitte führen Sie die folgenden Schritte aus</Description>
      <CardsContainer>
        <OnboardingCard
          done={hubspotProgress === 1}
          title="Hubspot Demo"
          description="Hubspot Demo mit Video"
          stepsDone={hubspotProgress}
          stepsTotal={1}
          linkTo="/onboarding/demo"
        />
        <OnboardingCard
          done
          title="Clockify"
          description="Hinterlegen Sie Ihre Konfiguration zum Terminbuchen"
          stepsDone={6}
          stepsTotal={6}
          linkTo="/onboarding/clockify"
        />
        <OnboardingCard
          title="Clockify"
          description="Hinterlegen Sie Ihre Konfiguration zum Terminbuchen"
          stepsDone={1}
          stepsTotal={6}
          linkTo="/onboarding/clockify"
        />
        <OnboardingCard
          title="Clockify"
          description="Hinterlegen Sie Ihre Konfiguration zum Terminbuchen"
          stepsDone={0}
          stepsTotal={2}
          linkTo="/onboarding/clockify"
        />
      </CardsContainer>
    </DefaultContainer>
  )
}

export default Onboarding
