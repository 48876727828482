import firebase from 'firebase/app'
import 'firebase/auth'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  firebaseReducer,
  ReactReduxFirebaseProvider
} from 'react-redux-firebase'
import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import { PersistGate } from 'redux-persist/integration/react'
import localForage from 'localforage'
import { SnackbarProvider } from 'notistack'
import { composeWithDevTools } from 'redux-devtools-extension'

import App from './App'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import Loading from './components/Loading'
import { ThemeProvider } from 'styled-components'
import { theme } from '@peakconcepts/leeeds-customer-portal-shared/dist'

const persistConfig = {
  key: 'root',
  storage: localForage
}
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true // Get custom claims along with the profile
}

// Initialize firebase instance
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SERNDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})
const rootReducer = combineReducers({
  firebase: persistReducer(
    { key: 'firebaseState', storage: localForage, stateReconciler: hardSet },
    firebaseReducer
  ),
  firestore: persistReducer(
    { key: 'firestoreState', storage: localForage, stateReconciler: hardSet },
    // @ts-ignore
    firestoreReducer
  )
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools())
export const persistor = persistStore(store)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </ReactReduxFirebaseProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
