import { FirebaseReduxReducer } from '../interfaces/redux'
import { createSelector } from 'reselect'

export const selectProfile = ({
  firebase
}: {
  firebase: FirebaseReduxReducer
}) => firebase.profile

export const selectAuth = ({ firebase }: { firebase: FirebaseReduxReducer }) =>
  firebase.auth

export const selectIsAdmin = createSelector(
  selectProfile,
  (profile) => profile.token?.claims?.admin === true
)
export const selectUID = createSelector(selectAuth, (auth) => auth.uid)
