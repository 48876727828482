import React from 'react'
import styled from 'styled-components'
import { FiArrowRightCircle, FiCheckCircle } from 'react-icons/fi'
import { useHistory } from 'react-router'

interface OnboardingCardInterface {
  done?: boolean
  linkTo: string
  title: string
  description: string
  stepsTotal?: number
  stepsDone?: number
}
//#region Styled Components
const Card = styled.button<{ done?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: ${({ done, theme }) =>
    done ? theme.colors.lightgray : 'white'};
  border: 1px solid rgba(200, 200, 200, 0.8);
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 30px;
  box-shadow: ${({ theme }) => theme.shadow};
  cursor: pointer;
`
const Title = styled.h3`
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
`
const Subtitle = styled.h5`
  text-align: left;
  font-weight: 500;
`
const Steps = styled.p`
  text-align: right;
  margin: 10px 0 0 auto;
  font-weight: 600;
  font-size: 16px;
`
const DoneIcon = styled(FiCheckCircle)`
  color: limegreen;
  font-size: 30px;
  align-self: center;
  margin-top: 10px;
`
const TodoIcon = styled(FiArrowRightCircle)`
  font-size: 30px;
  align-self: center;
  margin-top: 10px;
`
//#endregion
const OnboardingCard = ({
  done,
  linkTo,
  title,
  description,
  stepsDone,
  stepsTotal
}: OnboardingCardInterface) => {
  const history = useHistory()
  return (
    <Card onClick={() => history.push(linkTo)} done={done}>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
      {stepsTotal && (
        <Steps>
          {stepsDone} / {stepsTotal}
        </Steps>
      )}

      {done ? <DoneIcon /> : <TodoIcon />}
    </Card>
  )
}

export default OnboardingCard
