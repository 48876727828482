import MaterialTable from '@material-table/core'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { getUserRequests } from '@peakconcepts/leeeds-customer-portal-shared'
import { selectUID } from '../selectors/user'
import { useSelector } from 'react-redux'
import { appBarHeight } from '../components/Drawer'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import DefaultContainer from '../components/DefaultContainer'
import styled from 'styled-components'

const localization = {
  body: {
    emptyDataSourceMessage: 'Keine Einträge',
    addTooltip: 'Hinzufügen',
    deleteTooltip: 'Löschen',
    editTooltip: 'Bearbeiten',
    filterRow: {
      filterTooltip: 'Filter'
    },
    editRow: {
      deleteText: 'Diese Zeile wirklich löschen?',
      cancelTooltip: 'Abbrechen',
      saveTooltip: 'Speichern'
    }
  },
  grouping: {
    placeholder: 'Spalten ziehen ...',
    groupedBy: 'Gruppiert nach:'
  },
  header: {
    actions: 'Aktionen'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} von {count}',
    labelRowsSelect: 'Zeilen',
    labelRowsPerPage: 'Zeilen pro Seite:',
    firstAriaLabel: 'Erste Seite',
    firstTooltip: 'Erste Seite',
    previousAriaLabel: 'Vorherige Seite',
    previousTooltip: 'Vorherige Seite',
    nextAriaLabel: 'Nächste Seite',
    nextTooltip: 'Nächste Seite',
    lastAriaLabel: 'Letzte Seite',
    lastTooltip: 'Letzte Seite'
  },
  toolbar: {
    addRemoveColumns: 'Spalten hinzufügen oder löschen',
    nRowsSelected: '{0} Zeile(n) ausgewählt',
    showColumnsTitle: 'Zeige Spalten',
    showColumnsAriaLabel: 'Zeige Spalten',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export als CSV',
    searchTooltip: 'Suche',
    searchPlaceholder: 'Suche'
  }
}
const TableDetailsContainer = styled.div`
  padding: 20px;
  tr,
  td,
  th,
  tbody {
    font-family: 'Montserrat';
  }
`

const Requests = () => {
  const id = useSelector(selectUID)
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const { data: newData, columns: newColumns } = await getUserRequests(
        id,
        firebase.app()
      )
      setColumns(newColumns)
      setData(newData)
      setLoading(false)
    }
    if (id) getData()
  }, [id])
  return (
    <DefaultContainer>
      <MaterialTable
        localization={localization}
        isLoading={loading}
        title="Anfragen"
        columns={columns}
        detailPanel={[
          {
            tooltip: 'Alle Felder',
            render: (rowData) => {
              return (
                <TableDetailsContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 800 }}>Frage</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>
                          Antwort
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries({ ...rowData }).map(([key, value]) => {
                        if (
                          typeof value !== 'string' &&
                          typeof value !== 'number'
                        )
                          return null
                        return (
                          <TableRow key={key}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ fontWeight: 600 }}>
                              {key === 'created_at' ? 'Datum' : key}
                            </TableCell>
                            <TableCell>{value}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableDetailsContainer>
              )
            }
          }
        ]}
        data={data}
        options={{
          pageSize: 20,
          columnsButton: true,
          maxBodyHeight: `calc(100vh - ${appBarHeight + 227}px)`,
          overflowY: 'scroll'
        }}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </DefaultContainer>
  )
}

export default Requests
